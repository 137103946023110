// Colors
$brand-color: #92a3fd;
$brand-color-light: #9dceff;
$secondary-color: #c58bf2;
$secondary-color-light: #eea4ce;

$color-green: #55c57a;
$color-green-light: #7ed56f;
$color-green-dark: #28b485;

$color-orange-light: #ffb900;
$color-orange-dark: #ff7730;

$color-blue-light: #2998ff;
$color-blue-dark: #5643fa;

$color-mat-black: #1d1617;

$color-grey-light-1: #dddada;
$color-grey-normal-1: #ada4a5;
$color-grey-dark-1: #7b6f72;

$color-white: #fff;
$color-black: #000;

$border-color: #f7f8f8;

// Gradients
$blue-linear-gradient: linear-gradient(
  274.42deg,
  $brand-color 0%,
  $brand-color-light 124.45%
);
$purple-linear-gradient: linear-gradient(
  274.42deg,
  $secondary-color 0%,
  $secondary-color-light 124.45%
);

// Shadows
$blue-box-shadow: 0px 10px 22px rgba(149, 173, 254, 0.3);
$purble-box-shadow: 0px 10px 22px rgba(197, 139, 242, 0.3);
$card-box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);
