* {
  font-family: $font-stack;
  font-weight: normal;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

.container {
  padding: 30px 20px;
}

.ant-popover {
  max-width: 80%;
}

.ant-popover-inner {
  border-radius: 8px;
}
