.navigation {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background: white;
  padding: 30px;
  box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);

  &__button {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    padding: 0;

    > img {
      width: 24px;
      height: 24px;
    }
  }

  .active {
    position: relative;
    &::after {
      content: "";
      bottom: -8px;
      left: 50%;
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #9dceff;
      transform: translateX(-50%);
    }
  }

  &__button-center {
    position: absolute;
    top: -15px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: $blue-linear-gradient;
    border-radius: 100%;
    border: none;
    padding: 0;
    transform: translateX(-50%);
    box-shadow: 0px 10px 22px rgba(149, 173, 254, 0.3);

    > img {
      width: 28px;
      height: 28px;
    }
  }
}
