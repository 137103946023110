.input {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: $border-color;
    border-radius: 14px;
    padding: 0 15px;
    margin-top: 6px;

    &.input--textarea {
      min-height: 48px;
      height: auto;
      padding: 15px;
    }

    &.input--invalid {
      border: 1px solid red;
    }

    .select__element {
      width: 100%;
      height: 100%;
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-dark-1;
      background: none;
      border: none;
      appearance: none;
      outline: none;

      &:invalid {
        color: $color-grey-normal-1;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 18px;
      object-fit: contain;
    }

    &.icon--left {
      margin-right: 10px;
    }

    &.icon--right {
      margin-left: 10px;
    }
  }

  &__element {
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 18px;
    color: $color-grey-dark-1;
    background: none;
    border: none;
    outline: none;

    &::placeholder {
      color: $color-grey-normal-1;
    }

    &[type="date"] {
      &:not(:valid) {
        &:before {
          content: attr(placeholder);
          color: $color-grey-normal-1;
        }
      }
    }
  }
}
