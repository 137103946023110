.account {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading__text {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: $color-grey-dark-1;
      margin: 0;
    }

    .heading__title {
      font-size: 20px;
      line-height: 30px;
      font-weight: bold;
      text-align: center;
      color: $color-mat-black;
      margin: 5px 0 0 0;
    }
  }

  &__form {
    margin: 30px 0 0 0;

    .input-wrapper:not(:first-of-type) {
      margin-top: 15px;
    }

    .form__terms {
      font-size: 10px;
      line-height: 15px;
      color: $color-grey-normal-1;

      a {
        text-decoration: underline;
        color: $color-grey-dark-1;
      }
    }

    .form__submit-button {
      margin-top: 100px;
    }
  }

  &__divider {
    display: flex;
    margin: 20px 0;

    hr {
      border: none;
      border-top: 1px solid $border-color;
      width: 100%;
    }

    span {
      font-size: 12px;
      line-height: 18px;
      color: $color-mat-black;
      margin: 0 10px;
    }
  }

  &__login {
    .login__message {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: $color-mat-black;

      a {
        text-decoration: underline;
        color: $secondary-color;
      }
    }
  }
}
