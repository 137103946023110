.success {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    padding-top: 44px;
  }
}

.ilustration {
  &__image {
    width: 100%;
    object-fit: cover;
  }
}

.welcome-message {
  margin-top: 44px;

  &__title {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: $color-mat-black;
    margin: 0;
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $color-grey-dark-1;
    margin: 0;
  }
}
