.custom-header {
  &__head {
    .head__controls {
      display: grid;
      grid-template-columns: 32px 1fr 32px;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .head__button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: $border-color;
      border-radius: 8px;
    }

    .head__title {
      align-self: center;
      justify-self: center;
      font-size: 16px;
      line-height: 24px;
      color: $color-mat-black;
      margin: 0;
    }
  }
}
