.submit-training {
  &__card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-top: 30px;

    .card__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $blue-linear-gradient;
      border-radius: 16px;
      opacity: 0.2;
    }

    .card__icon {
      display: flex;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
    }

    .card__text {
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-dark-1;
      margin: 0;
    }
  }

  &__details {
    margin-top: 30px;

    .details {
      &__title {
        font-size: 14px;
        line-height: 21px;
        color: $color-mat-black;
      }

      &__form {
        .input-wrapper:not(:first-of-type) {
          margin-top: 15px;
        }

        .form__submit-button {
          margin-top: 15px;
        }
      }
    }
  }

  &__content {
    .content__ilustration {
      &__image {
        width: 100%;
        object-fit: cover;
      }
    }

    .content__message {
      margin-top: 36px;

      .message {
        &__title {
          font-size: 20px;
          line-height: 30px;
          font-weight: bold;
          text-align: center;
          color: $color-mat-black;
          margin: 0;
        }

        &__text {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: $color-grey-dark-1;
          margin: 15px 0 0 0;
        }
      }
    }
  }

  &__button {
    padding-top: 44px;
  }
}
