.input-wrapper {
  &__label {
    font-size: 14px;
    line-height: 22px;
    color: $color-mat-black;

    span {
      color: red;
    }
  }

  &__error-message {
    font-size: 10px;
    line-height: 14px;
    color: red;
    margin: 6px 0 0 6px;
  }
}
