.signup-goal-step {
  &__heading {
    display: flex;
    flex-direction: column;

    .heading {
      &__ilustration {
        width: 100%;
        object-fit: contain;
      }

      &__content {
        margin-top: 30px;
      }

      &__title {
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
        color: $color-mat-black;
        margin: 0;
      }

      &__text {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $color-grey-dark-1;
        margin: 5px 0 0 0;
      }
    }
  }

  &__form {
    margin-top: 24px;

    .form {
      &__input-box {
        display: grid;
        grid-template-columns: 1fr 48px;
        gap: 15px;

        .box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 14px;
          background: $purple-linear-gradient;
          margin-top: 6px;

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
          }
        }
      }

      &__submit-button {
        margin-top: 30px;
      }
    }
  }
}
