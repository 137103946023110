.training-page {
  position: relative;

  &__head {
    .head__controls {
      position: absolute;
      margin-top: 30px;
      padding: 0 20px;
      z-index: 9;
    }
  }

  &__top {
    position: relative;
    display: flex;
    height: 320px;
    background: $blue-linear-gradient;

    &::before {
      position: absolute;
      left: 50%;
      bottom: -20px;
      content: "";
      width: 250px;
      height: 250px;
      border-radius: 200px;
      background: white;
      opacity: 0.3;
      transform: translateX(-50%);
      z-index: 1;
    }

    .top__ilustration {
      position: relative;
      align-self: flex-end;
      width: 100%;
      height: 80%;
      object-fit: contain;
      padding-bottom: 40px;
      z-index: 2;
    }
  }

  &__content {
    position: relative;
    margin-top: -40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background: white;
    padding: 10px 20px 30px;
    z-index: 9;

    .content {
      &__slash {
        width: 50px;
        height: 5px;
        background: $color-mat-black;
        border-radius: 2.5px;
        opacity: 0.1;
        margin: 0 auto;
      }

      &__infos {
        margin-top: 25px;

        .infos__title {
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          color: $color-mat-black;
          margin: 0;
        }

        .infos__text {
          font-size: 12px;
          line-height: 18px;
          color: $color-grey-dark-1;
          margin: 5px 0 0 0;
        }
      }

      &__difficulty {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        margin-top: 20px;

        .difficulty__bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $purple-linear-gradient;
          border-radius: 16px;
          opacity: 0.2;
        }

        .difficulty__icon {
          display: flex;
          align-items: center;

          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
        }

        .difficulty__text {
          font-size: 12px;
          line-height: 18px;
          color: $color-grey-dark-1;
          margin: 0;
        }
      }

      &__training {
        .training__top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 30px 0 20px;

          .top__title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $color-mat-black;
            margin: 0;
          }

          .top__text {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: $color-grey-normal-1;
            margin: 0;
          }
        }

        .training__training-set {
          .training-set {
            &__top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
            }

            &__title {
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: $color-mat-black;
              margin: 0;
            }

            &__popover {
              button {
                width: 35px;
                height: 35px;
                color: white;
                background: $blue-linear-gradient;
                border: none;
                border-radius: 100%;
              }
            }

            &__exercise {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 15px;

              &:not(:first-of-type) {
                margin: 0;
              }

              .exercise {
                &__left {
                  display: flex;
                  align-items: center;
                }

                &__image {
                  width: 60px;
                  height: 60px;
                  object-fit: cover;
                  border-radius: 12px;
                  margin-right: 10px;
                }

                &__description {
                  display: flex;
                  flex-direction: column;

                  .description__title {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    color: $color-mat-black;
                    margin: 0;
                  }

                  .description__text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: $color-grey-dark-1;
                    margin: 5px 0 0 0;
                  }
                }
              }
            }

            &__exercise-interval {
              position: relative;
              display: flex;
              align-items: center;
              width: 100%;
              height: 30px;
              margin: 15px 0;

              &::before {
                content: "";
                position: absolute;
                left: 30px;
                width: 1px;
                height: 100%;
                background: $color-mat-black;
              }

              p {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $color-grey-dark-1;
                margin: 0 0 0 70px;
              }
            }

            &__repeat {
              display: flex;
              align-items: center;
              width: 100%;
              height: 30px;
              margin: 15px 0;

              div {
                display: flex;
                align-items: center;
                margin-left: 22px;
              }

              p {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: $color-grey-dark-1;
                margin: 0 0 0 10px;
              }
            }

            &__divider {
              border: none;
              border-top: 1px solid $color-grey-light-1;
              width: 100%;
              margin: 20px 0;
            }
          }
        }

        .training__finish-button {
          margin-top: 30px;
        }
      }
    }
  }
}
