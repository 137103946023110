.feed {
  min-height: 100vh;
  padding-bottom: 90px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header__content {
      p {
        font-size: 12px;
        line-height: 18px;
        color: $color-grey-normal-1;
        margin: 0;
      }

      h2 {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        margin: 5px 0 0 0;
      }
    }

    .header__logout {
      width: 40px;
      height: 40px;
      background-color: $border-color;
      border-radius: 8px;
      border: none;
      padding: 0;

      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
    }
  }

  &__content {
    margin-top: 20px;
  }

  &__post {
    width: 100%;
    border-radius: 16px;
    padding: 15px 10px;
    margin-bottom: 15px;
    box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);

    .post__header {
      display: flex;
      height: 55px;

      > img {
        width: 55px;
        height: 55px;
        object-fit: cover;
        border-radius: 100%;
      }

      .header__infos {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        h3 {
          font-size: 14px;
          line-height: 21px;
          color: $color-mat-black;
          margin: 3px 0 0 0;
        }

        p {
          font-size: 10px;
          line-height: 15px;
          color: $color-grey-normal-1;
          margin: 0;
        }
      }
    }

    .post__content {
      display: flex;
      align-items: center;
      width: calc(100% + 20px);
      margin: 10px 0 0 -10px;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;

      .content__item {
        min-width: 100%;
        scroll-snap-align: start;

        > img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .post__footer {
      margin-top: 10px;

      .footer__description {
        font-size: 12px;
        line-height: 18px;
        color: $color-mat-black;

        .description__user {
          font-weight: bold;
        }

        .description__read-more {
          font-weight: 500;
          color: $brand-color;
        }
      }

      .description__text--long {
        display: none;
      }
    }
  }
}
