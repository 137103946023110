.finished-training {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content__ilustration {
      &__image {
        width: 100%;
        object-fit: cover;
      }
    }

    .content__message {
      margin-top: 36px;

      .message {
        &__title {
          font-size: 20px;
          line-height: 30px;
          font-weight: bold;
          text-align: center;
          color: $color-mat-black;
          margin: 0;
        }

        &__text {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: $color-grey-dark-1;
          margin: 15px 0 0 0;
        }
      }
    }
  }

  &__button {
    padding-top: 44px;
  }
}
