@keyframes btn-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue-linear-gradient;
  border: none;
  border-radius: 30px;
  filter: drop-shadow(0px 10px 22px rgba(149, 173, 254, 0.3));
  font-weight: bold;
  color: white;
  padding: 0 20px;
  transition: all 0.1s;

  &:active {
    transform: scale(0.98);
  }

  &.loading {
    opacity: 0.4;
    cursor: not-allowed;

    &:active {
      transform: scale(1);
    }

    > .loading__spinner {
      display: inline-block;
      border-top: 2px solid currentcolor;
      border-right: 2px solid currentcolor;
      border-bottom-style: solid;
      border-left-style: solid;
      border-radius: 99999px;
      border-bottom-width: 2px;
      border-left-width: 2px;
      border-bottom-color: transparent;
      border-left-color: transparent;
      animation: 0.45s linear 0s infinite normal none running
        btn-spinner-animation;
      width: 1em;
      height: 1em;
      --spinner-size: 1.5rem;
      color: currentcolor;
    }

    > .loading__text {
      margin-left: 8px;
    }
  }
}

.btn--lg {
  font-size: 16px;
  line-height: 24px;
  padding: 18px 54px;

  &.loading > .loading__spinner {
    width: 24px;
    height: 24px;
  }
}

.btn--sm {
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  padding: 10px 30px;

  &.loading > .loading__spinner {
    width: 15px;
    height: 15px;
  }
}

.btn--full {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
