.exercise-page {
  .video-react-big-play-button {
    display: none;
  }

  &__video {
    position: relative;
    width: 100%;
    height: 150px;
    border-radius: 22px;
    margin-top: 30px;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 22px;
      z-index: 9;
    }

    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 22px;
      z-index: 8;
    }

    .video__play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 28px;
      height: 28px;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }

  &__exercise {
    margin-top: 20px;

    .exercise__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-mat-black;
      margin: 0;
    }

    .exercise__text {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-dark-1;
      margin: 5px 0 0 0;
    }
  }

  &__description {
    margin-top: 30px;

    .description__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-mat-black;
      margin: 0;
    }

    .description__text {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-dark-1;
      margin: 15px 0 0 0;
    }

    .description__read-more {
      font-weight: 500;
      color: $brand-color;
    }

    .description__text--long {
      display: none;
    }
  }

  &__how-to-do {
    margin-top: 30px;

    .how-to-do__title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .how-to-do__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-mat-black;
      margin: 0;
    }

    .how-to-do__steps {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-normal-1;
      margin: 0;
    }

    .how-to-do__list {
      position: relative;
      display: inline-block;
      margin: 15px 0 0 0;
      padding-inline-start: 0px;

      &::before {
        position: absolute;
        top: 0;
        left: 9px;
        content: "";
        width: 2px;
        height: 100%;
        background-image: linear-gradient(
          $secondary-color-light 33%,
          rgba(255, 255, 255, 0) 0%
        );
        background-position: right;
        background-size: 2px 15px;
        background-repeat: repeat-y;
      }

      .how-to-do__list-item {
        position: relative;
        list-style-type: none;
        padding: 0 0 30px 35px;

        &::before {
          content: url("/assets/icons/custom-list-icon.svg");
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 30px;
          background: white;
          padding: 5px 0;
        }

        &:first-of-type {
          &::before {
            height: 25px;
            padding-top: 0;
          }
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        .list-item__title {
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: $color-mat-black;
          margin: 5px 0 0 0;
        }

        .list-item__text {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $color-grey-dark-1;
          margin: 5px 0 0 0;
        }
      }
    }
  }

  &__last-weight {
    margin-top: 30px;

    .last-weight__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $color-mat-black;
      margin: 0;
    }

    .last-weight__form {
      margin-top: 15px;

      .form__input-box {
        display: grid;
        grid-template-columns: 1fr 48px;
        gap: 15px;

        .box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          border-radius: 14px;
          background: $purple-linear-gradient;
          margin-top: 6px;

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
          }
        }
      }

      .form__submit-button {
        margin-top: 30px;
      }
    }
  }
}
