.settings {
  &__profile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;

    .profile__wrapper {
      display: flex;
      align-items: center;

      > div:not(:first-of-type) {
        margin-left: 15px;
      }
    }

    .profile__title {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      color: $color-mat-black;
      margin: 0;
    }

    .profile__subtitle {
      font-size: 12px;
      line-height: 18px;
      color: $color-grey-dark-1;
      margin: 5px 0 0 0;
    }

    button {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      padding: 6px 30px;
    }
  }

  &__profile-infos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 15px;

    .profile-infos__card {
      width: 100%;
      height: 65px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-radius: 16px;
      padding: 10px 0;
      box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);

      h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        background: $blue-linear-gradient;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
      }

      p {
        font-size: 12px;
        line-height: 18px;
        color: $color-grey-dark-1;
        margin: 0;
      }
    }
  }

  &__card {
    background: white;
    border-radius: 16px;
    margin-top: 30px;
    padding: 20px 15px 20px 20px;
    box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);

    h2 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $color-mat-black;
    }

    .card__list {
      list-style: none;
      margin: 15px 0 0 0;
      padding: 0;
    }

    .card__list-item {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }

    .list-item__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-switch-checked {
        background: $purple-linear-gradient;
      }
    }

    .list-item__title {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      p {
        margin: 0;
      }
    }
  }
}
